import { makeStyles } from '@mui/styles';
import { Typography, ListItemIcon } from '@mui/material';
import { useRef, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import LinkIcon from '@mui/icons-material/Link';
import { YearAccordion } from './YearAccordian';

const useStyles = makeStyles((theme) => ({
  subheading: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
}));

export const ReleaseInfo = () => {
  const mappingsReleaseData = [
    {
      year: 'Release notes for the year 2023',
      releases: [
        {
          month: 'September',
          title: 'Introducing Mappings',
          changes: [
            { label: 'Date', value: '25.09.2023' },
            {
              label: 'An introduction header and brief overview of the changes',
              value:
                'This is the first release of the Ontology Mapping component within the Terminology Service Sandbox. The release includes an extension of WebProtégé to directly interact with Git. Besides the tool as such, a documentation and a questionnaire have been published.',
            },
            {
              label: 'Changes to Previous Release Notes',
              value: 'Initial release.',
            },
            {
              label: 'Feature Enhancements or New Features/Functionality',
              value: 'Initial release.',
            },
            {
              label: 'Fixed Issues',
              value: 'Initial release.',
            },
            {
              label: 'Ongoing Issues/Challenges/Obstacles/Limitations',
              value: 'We are working on stabilizing the code to make the tool more robust. We investigate into new mapping views and assessment tools',
            },
          ],
        },
        {
          month: 'October',
          title: 'Filtering and Linking Graphs',
          changes: [
            { label: 'Date', value: '17.10.2023' },
            {
              label: 'An introduction header and brief overview of the changes',
              value: 'Filtering graph using collection names. Linking graph to the mapping tables. The layout, backend and frontend has been updated and improved.',
            },
            {
              label: 'Changes to Previous Release Notes',
              value: 'changes from previous release dated on 25.09.2023',
            },
            {
              label: 'Feature Enhancements or New Features/Functionality',
              value: 'Filtering based on collection.',
            },
            {
              label: 'Fixed Issues',
              value: 'Linked mapping tables to mapping graphs',
            },
            {
              label: 'Ongoing Issues/Challenges/Obstacles/Limitations',
              value: 'When selecting one or more collection names, user can filter mappings graph by selecting source ontology ids that belong to selected collections',
            },
          ],
        },
        {
          month: 'November',
          title: 'Filtering Mappings Graph Based on Selected Source Ontology Ids',
          changes: [
            { label: 'Date', value: '20.11.2023' },
            {
              label: 'An introduction header and brief overview of the changes',
              value: 'Filtering mappings graph based on selected one or more source ontology ids that belong to selected collections. The layout, backend and frontend has been updated and improved.',
            },
            {
              label: 'Changes to Previous Release Notes',
              value: 'changes from previous release dated on 17.10.2023',
            },
            {
              label: 'Feature Enhancements or New Features/Functionality',
              value: 'Filtering based on source ontology ids and collection names.',
            },
            {
              label: 'Fixed Issues',
              value: 'Change the color of a source ontology node id from red to blue when a user selects or unselect the source ontology node id.',
            },
            {
              label: 'Ongoing Issues/Challenges/Obstacles/Limitations',
              value: 'Upload an ontology URL and produce mappings for that ontology as a source and selected set of target ontologies from ts',
            },
          ],
        },
      ],
    },
    {
      year: 'Release notes for the year 2024',
      releases: [
        {
          month: 'January 2024',
          title: 'Generate mappings based on external ontolgy URL and selected TIB TS ontologies',
          changes: [
            { label: 'Date', value: '08.01.2024' },
            {
              label: 'An introduction header and brief overview of the changes',
              value:
                'The user is required to provide a URL for an external ontology file hosted on Github or Gitlab ' +
                'and select one or more ontology names that have been ingested into the TIB Terminology Service (TS). ' +
                'The service generates mappings between ontologies based on this input, displays the resulting mappings, ' +
                'and offers the user the option to download the mappings in JSON format. ' +
                'A REST call to this service is also available.',
            },
            {
              label: 'Changes to Previous Release Notes',
              value: 'changes from previous release dated on 20.11.2023.',
            },
            {
              label: 'Feature Enhancements or New Features/Functionality',
              value:
                'The service generates mappings using an external ontology URL and selected ontologies from TIB TS.' +
                'The mappings results are displayed in a table and graph, and users can explore mapping details. Mappings ' +
                'with  erros or zero mappings are highlighted, as well as conflictive mappings results. The produced mappings' +
                'can be downloaded in JSON format',
            },
            {
              label: 'Fixed Issues',
              value: 'Enable/disable submit button, validate external ontology URL, reuse components to display mappings.',
            },
            {
              label: 'Ongoing Issues/Challenges/Obstacles/Limitations',
              value: 'To implement progress bar. To enable a description logics reasoner (HermiT) in producing mappings.',
            },
          ],
        },
        {
          month: 'January',
          title: 'Enable HermiT reasoner when producing mappings based on external ontology and optionally to check classes satisfiability',
          changes: [
            { label: 'Date', value: '19.01.2024' },
            {
              label: 'An introduction header and brief overview of the changes',
              value:
                'The HermiT reasoner is included in the list of default parameters of the LogMap Matcher. This service uses the ' +
                'HermiT reasoner to detect ontology incosnistency, unsatisfiability of classes in the ontology resulting from the ' +
                'integration of source ontology, target ontology, and produced mappings as an OWL ontology.  ' +
                'Users can choose to enable or disable the check for unsatisfiability of classes in the integrated ontology ' +
                'when performing mappings between an external ontology and selected TIB TS ontologies. In mapping results ' +
                'the Actions column constains a report button that allows users to view mapping explanation. The explanation contains ' +
                'details about the reasoning, and explanation of exceptions that may occur when generating mapppings. The explanation also ' +
                'contains basic information about source ontology and target ontology. A REST call to this service is also available.',
            },
            {
              label: 'Changes to Previous Release Notes',
              value: 'changes from previous release dated on 08.01.2024.',
            },
            {
              label: 'Feature Enhancements or New Features/Functionality',
              value:
                'The HermiT reasoner is used to generate mappings. ' +
                'The mapping results, which are displayed as a table, include a report that provides an explanation of the reasoning ' +
                'results and an explanation of the exceptions that may occur when producing mappings. Checking the satisfiability ' +
                'of classes in the integrated source ontology, target ontology, and mappings as an ontology is set to on or off. ',
            },
            {
              label: 'Fixed Issues',
              value:
                'Turn on or off checking classes satisfiability. Report about resoning result or exceptions that ' + 'may occur when producing mappings. Report is accessible from the Actions column.',
            },
            {
              label: 'Ongoing Issues/Challenges/Obstacles/Limitations',
              value: 'To implement progress bar (spinner).',
            },
          ],
        },
        {
          month: 'February',
          title: 'Implementation Spinner, removed external ontology file extension, refined explanation text, compute mapping between two external ontologies',
          changes: [
            { label: 'Date', value: '09.02.2024' },
            {
              label: 'An introduction header and brief overview of the changes',
              value:
                'The spinner is implemented when running external mappings. A REST call is implemented to generate mappings between ' +
                'two external ontologies, which were prodived by their ontology URLs. The REST call is accessible outside of TIB network. ' +
                'The text explaning how to use the mapping services ' +
                'has been revised using DeepL AI tool. Icons in the action column have been replaced with new icons. ' +
                'Figures diplsayed in how to use mappings component have been updated accordingly. ',
            },
            {
              label: 'Changes to Previous Release Notes',
              value: 'changes from previous release dated on 19.01.2024.',
            },
            {
              label: 'Feature Enhancements or New Features/Functionality',
              value: 'Spinner.',
            },
            {
              label: 'Fixed Issues',
              value: 'Spinner implementation. Revised text that explains how to use mapping services',
            },
            {
              label: 'Ongoing Issues/Challenges/Obstacles/Limitations',
              value: 'Improve explanations of reasoning inconsistency and unsatisfibaility when they occur.' + 'Improve the explanation of runtime mappings exceptions if they occur.',
            },
          ],
        },
      ],
    },
  ];

  const webprotegeReleaseData = [
    {
      year: 'Release notes for the year 2023',
      releases: [
        {
          month: 'September 2023',
          title: 'Introducing Webprotege',
          changes: [
            { label: 'Date', value: '25.09.2023' },
            {
              label: 'An introduction header and brief overview of the changes',
              value:
                'This is the first release of the WebProtege component within the Terminology Service Sandbox. The release includes an extension of WebProtégé to directly interact with Git. Besides the tool as such, a documentation and a questionnaire have been published.',
            },
            {
              label: 'Changes to Previous Release Notes',
              value: 'Initial release.',
            },
            {
              label: 'Feature Enhancements or New Features/Functionality',
              value: 'Initial release.',
            },
            {
              label: 'Fixed Issues',
              value: 'Initial release.',
            },
            {
              label: 'Ongoing Issues/Challenges/Obstacles/Limitations',
              value: 'We are working on a more user friendly navigation and on stabilizing the code to make the tool more robust.',
            },
          ],
        },
      ],
    },
  ];

  const diffReleaseData = [
    {
      year: 'Release notes for the year 2023',
      releases: [
        {
          month: 'November 2023',
          title: 'Introducing Semantic Diff',
          changes: [
            { label: 'Date', value: '02.1.2023' },
            {
              label: 'An introduction header and brief overview of the changes',
              value:
                'This is the first release of the Semantic Diff component within the Terminology Service Sandbox. ' +
                'The release includes the tool to calculate semantic differences in ontologies hosted on GIT-systems. ' +
                'Besides the tool as such, a documentation and a questionnaire have been published.',
            },
            {
              label: 'Changes to Previous Release Notes',
              value: 'Initial release.',
            },
            {
              label: 'Feature Enhancements or New Features/Functionality',
              value: 'Initial release.',
            },
            {
              label: 'Fixed Issues',
              value: 'Initial release.',
            },
            {
              label: 'Ongoing Issues/Challenges/Obstacles/Limitations',
              value: 'We are working on a more user friendly navigation and on stabilizing the code to make the tool more robust.',
            },
          ],
        },
      ],
    },
  ];

  const annotationServiceReleaseData = [
    {
      year: 'Release notes for the year 2023',
      releases: [
        {
          month: 'December 2023',
          title: 'Introducing Annotation Service',
          changes: [
            { label: 'Date', value: '05.12.2023' },
            {
              label: 'An introduction header and brief overview of the changes',
              value:
                'The tool is intended to demonstrate the indexing of texts based on ontologies in a prototypical way at first.' +
                'we did advancements in our processes, specifically focused on data preprocessing and model optimization.' +
                ' By refining our data preprocessing pipeline, we ensure high-quality datasets, enhancing overall model performance. Additionally, strategic adjustments to model parameters have been made, optimizing efficiency and accuracy.',
            },
            {
              label: 'Changes to Previous Release Notes',
              value: 'Initial release.',
            },
            {
              label: 'Feature Enhancements or New Features/Functionality',
              value: 'Initial release.',
            },
            {
              label: 'Fixed Issues',
              value: 'Initial release.',
            },
            {
              label: 'Ongoing Issues/Challenges/Obstacles/Limitations',
              value:
                'The tool will be extended successively with further functionalities. Among other things, this concerns the performance, the removal of the restriction to recognise only the 11 parent UAT terms, but also the transfer to other ontologies.',
            },
          ],
        },
      ],
    },
  ];

  const semanticSearchReleaseData = [
    {
      year: 'Release notes for the year 2024',
      releases: [
        {
          month: 'December 2024',
          title: 'Introducing Semantic Search',
          changes: [
            { label: 'Date', value: '12.02.2024' },
            {
              label: 'An introduction header and brief overview of the changes',
              value:
                'This tool is intended to discover similar entities of the ontologies leveraging Sentence-BERT model all-MiniLM-L6-v2.' +
                'The text corpus data is prepared from TIB Terminology Service ontologies, ensuring a rich and diverse dataset for semantic search.',
            },
            {
              label: 'Changes to Previous Release Notes',
              value: 'Initial release.',
            },
            {
              label: 'Feature Enhancements or New Features/Functionality',
              value: 'Initial release.',
            },
            {
              label: 'Fixed Issues',
              value: 'Initial release.',
            },
            {
              label: 'Ongoing Issues/Challenges/Obstacles/Limitations',
              value: 'Currently the text corpus include non-english words which will be removed in future.',
            },
          ],
        },
      ],
    },
  ];

  const PidCheckerReleaseData = [
    {
      year: 'Release notes for the year 2024',
      releases: [
        {
          month: 'October 2024',
          title: 'Introducing PID Checker Service',
          changes: [
            { label: 'Date', value: '01.10.2024' },
            {
              label: 'An introduction header and brief overview of the changes',
              value:
                  'We are excited to announce the first release of the PID Checker Service component within the Terminology Service. This initial version provides a comprehensive list of down IRI and total number of IRIs within each ontology. Users can easily identify how many IRIs conform to specific Persistent Identifier (PID) patterns, including DOI, Handle, PURL, URN, ARK, and W3ID. For example, the output may read "5 PURL, 10 DOI." If no PID patterns are found, the service will indicate "No PID patterns available," along with a detailed overview of the down IRIs.',
            },
            {
              label: 'Changes to Previous Release Notes',
              value: 'Initial release.',
            },
            {
              label: 'Feature Enhancements or New Features/Functionality',
              value: 'Initial release.',
            },
            {
              label: 'Fixed Issues',
              value: 'Initial release.',
            },
            {
              label: 'Ongoing Issues/Challenges/Obstacles/Limitations',
              value: 'We are currently integrating additional ontologies into platform and enhance its reliability.',
            },
          ],
        },
      ],
    },
  ];

  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const mappingsRef = useRef(null);
  const pairwiseSimilarityRef = useRef(null);
  const semanticDiffRef = useRef(null);
  const qualityAssessmentRef = useRef(null);
  const webProtegeRef = useRef(null);
  const webVowlRef = useRef(null);
  const annotationServiceRef = useRef(null);
  const semanticSearchRef = useRef(null);
  const pidCheckerRef = useRef(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const scrollTo = searchParams.get('scrollTo');

    if (scrollTo === 'mappings') {
      mappingsRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (scrollTo === 'webProtege') {
      webProtegeRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (scrollTo === 'historyManagement') {
      semanticDiffRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (scrollTo === 'annotationService') {
      annotationServiceRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (scrollTo === 'semanticSearch') {
      annotationServiceRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (scrollTo === 'pairwiseSimilarity') {
      pairwiseSimilarityRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (scrollTo === 'qualityAssessment') {
      qualityAssessmentRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (scrollTo === 'webVowl') {
      webVowlRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (scrollTo === 'pidCheckerService') {
      pidCheckerRef.current.scrollIntoView({ behavior: 'smooth' });
  }
  }, [location.search]);

  return (
      <div style={{justifySelf: 'flex-start'}}>
        <Typography variant="h4">Release Info</Typography>
        <div className={classes.subheading} ref={mappingsRef}>
          <ListItemIcon onClick={() => history.push('/ts/sandbox/mappingTableView')}>
            <LinkIcon/>
          </ListItemIcon>
          <Typography variant="h5" onClick={() => history.push('/ts/sandbox/mappingTableView')}>
            Mappings
          </Typography>
        </div>
        <div>
          {mappingsReleaseData.map((releaseData, index) => (
              <YearAccordion key={index} {...releaseData} />
          ))}
        </div>
        <div className={classes.subheading} ref={webProtegeRef}>
          <ListItemIcon onClick={() => history.push('/ts/sandbox/webprotege')}>
            <LinkIcon/>
          </ListItemIcon>
          <Typography variant="h5" onClick={() => history.push('/ts/sandbox/webprotege')}>
            WebProtege
          </Typography>
        </div>
        <div>
          {webprotegeReleaseData.map((releaseData, index) => (
              <YearAccordion key={index} {...releaseData} />
          ))}
        </div>

        <div className={classes.subheading} ref={semanticDiffRef}>
          <ListItemIcon onClick={() => history.push('/ts/sandbox/semanticdiff')}>
            <LinkIcon/>
          </ListItemIcon>
          <Typography variant="h5" onClick={() => history.push('/ts/sandbox/semanticdiff')}>
            Semantic Diff
          </Typography>
        </div>
        <div>
          {diffReleaseData.map((releaseData, index) => (
              <YearAccordion key={index} {...releaseData} />
          ))}
        </div>

        <div className={classes.subheading} ref={annotationServiceRef}>
          <ListItemIcon onClick={() => history.push('/ts/sandbox/annotation')}>
            <LinkIcon/>
          </ListItemIcon>
          <Typography variant="h5" onClick={() => history.push('/ts/sandbox/annotation')}>
            Annotation Service
          </Typography>
        </div>
        <div>
          {annotationServiceReleaseData.map((releaseData, index) => (
              <YearAccordion key={index} {...releaseData} />
          ))}
        </div>

        <div className={classes.subheading} ref={pidCheckerRef}>
          <ListItemIcon onClick={() => history.push('/ts/sandbox/pidChecker')}>
            <LinkIcon/>
          </ListItemIcon>
          <Typography variant="h5" onClick={() => history.push('/ts/sandbox/pidChecker')}>
            PID Checker Service
          </Typography>
        </div>
        <div>
          {PidCheckerReleaseData.map((releaseData, index) => (
              <YearAccordion key={index} {...releaseData} />
          ))}
        </div>
        {/* <div className={classes.subheading} ref={semanticSearchRef}>
        <ListItemIcon onClick={() => history.push('/ts/sandbox/semanticSearch')}>
          <LinkIcon />
        </ListItemIcon>
        <Typography variant="h5" onClick={() => history.push('/ts/sandbox/semanticSearch')}>
          Semantic Search
        </Typography>
      </div>
      <div>
        {semanticSearchReleaseData.map((releaseData, index) => (
          <YearAccordion key={index} {...releaseData} />
        ))}
      </div> */}
      </div>
  );
};
