import OntologyList from './components/Ontologies/OntologyList/OntologyList';
import OntologyPage from './components/Ontologies/OntologyPage/OntologyPage';
import Home from './components/Home/Home';
import SearchResult from './components/Search/SearchResult/SearchResult';
import Documentation from './components/Documentation/Documentation';
import Collections from './components/Collection/Collection';
import Imprint from './components/Imprint/imprint';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import TermsOfUse from './components/TermsOfUse/TermsOfUse';
import About from './components/About/About';
import Help from './components/Help/Help';
import UsagePage from './components/Usage/Usage';
import SubmitedIssueRequests from './components/User/SubmitedIssueRequests/SubmitedIssueRequests';
import UserProfile from './components/User/Profile/Profile';
import RequireLoginRoute from './components/User/Login/RequireLoginRoute';
import UserPanel from './components/User/Login/UserPanel';
import ReportPanel from './components/User/Admin/ReportPanel';
import Sitemap from './components/Sitemap/Sitemap';
import { Route, Switch } from 'react-router-dom';
import UserCollection from './components/User/Collection/Collection';

// Sandbox related imports
import { SearchMappings } from './components/sandbox/mappings/searchMappings/SearchMappings';
import { WebProtege } from './components/sandbox/webprotege/WebProtege';
import SearchMappingsPage from './components/sandbox/mappings/mappingsPage/SearchMappingsPage';
import { AddRobotDiff } from './components/sandbox/historyManagement/AddRobotDiff';
import { Criteria } from './components/sandbox/historyManagement/Criteria';
import TimelineOntology from './components/sandbox/historyManagement/TimelineOntology';
import SandboxLayout from './components/sandbox/common/SandboxLayout';
import { ReleaseInfo } from './components/sandbox/releaseInfo/ReleaseInfo';
import TableViewMapping from './components/sandbox/mappings/searchMappings/TableViewMapping';
import MappingGraphBetweenOntologies from './components/sandbox/mappings/mappingsGraph/MappingGraphBetweenOntologies';
import { HistoryManagement } from './components/sandbox/historyManagement/HistoryManagement';
import { FundingInfo } from './components/sandbox/fundingInfo/FundingInfo';
import NerForm from './components/sandbox/ner/NERForm';
import { SandboxHome } from './components/sandbox/home/SandboxHome';
import HowToUse from './components/sandbox/common/buttons/HowToUse';
import GenerateMapping from './components/sandbox/mappings/generateMappings/GenerateMapping';
import { ModelSelector } from './components/sandbox/word2vec/ModelSelector';
import { AddConto } from './components/sandbox/historyManagement/contoDiff/AddConto';
import { ContoManagement } from './components/sandbox/historyManagement/contoDiff/ContoManagement';
import { DiffTable } from './components/sandbox/historyManagement/DiffTable';
import ChangesTimeline from './components/sandbox/historyManagement/contoDiff/ChangesTimeline';
import { PIDChecker } from './components/sandbox/pid/PIDChecker';

const AppRouter = () => {
  return (
    <Switch>
      <Route exact path={process.env.REACT_APP_PROJECT_SUB_PATH + '/'} component={Home} />
      <Route path={process.env.REACT_APP_PROJECT_SUB_PATH + '/login'} component={UserPanel} />
      <RequireLoginRoute path={process.env.REACT_APP_PROJECT_SUB_PATH + '/myprofile'} component={UserProfile} />
      <RequireLoginRoute path={process.env.REACT_APP_PROJECT_SUB_PATH + '/submitedIssueRequests'} component={SubmitedIssueRequests} />
      <RequireLoginRoute path={process.env.REACT_APP_PROJECT_SUB_PATH + '/reports'} component={ReportPanel} />
      <RequireLoginRoute path={process.env.REACT_APP_PROJECT_SUB_PATH + '/mycollections'} component={UserCollection} />
      <Route exact path={process.env.REACT_APP_PROJECT_SUB_PATH + '/ontologies'} component={OntologyList} />
      <Route exact path={process.env.REACT_APP_PROJECT_SUB_PATH + '/ontologies/:ontologyId/:tab?'} component={OntologyPage} />
      <Route exact path={process.env.REACT_APP_PROJECT_SUB_PATH + '/api'} component={Documentation} />
      <Route exact path={process.env.REACT_APP_PROJECT_SUB_PATH + '/docs'} component={Documentation} />
      <Route exact path={process.env.REACT_APP_PROJECT_SUB_PATH + '/search'} component={SearchResult} />
      <Route exact path={process.env.REACT_APP_PROJECT_SUB_PATH + '/imprint'} component={Imprint} />
      <Route exact path={process.env.REACT_APP_PROJECT_SUB_PATH + '/PrivacyPolicy'} component={PrivacyPolicy} />
      <Route exact path={process.env.REACT_APP_PROJECT_SUB_PATH + '/TermsOfUse'} component={TermsOfUse} />
      <Route exact path={process.env.REACT_APP_PROJECT_SUB_PATH + '/about'} component={About} />
      <Route exact path={process.env.REACT_APP_PROJECT_SUB_PATH + '/help'} component={Help} />
      <Route path={process.env.REACT_APP_PROJECT_SUB_PATH + '/sitemap'} component={Sitemap} />
      {process.env.REACT_APP_COLLECTION_TAB_SHOW === 'true' && <Route exact path={process.env.REACT_APP_PROJECT_SUB_PATH + '/collections'} component={Collections} />}
      {process.env.REACT_APP_PROJECT_ID === 'nfdi4ing' && <Route exact path={process.env.REACT_APP_PROJECT_SUB_PATH + '/usage'} component={UsagePage} />}
      <Route path={process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox'}>
        <SandboxLayout>
          <Route exact path={process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox'} component={SandboxHome} />
          <Route exact path={process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/searchMappings'} component={SearchMappings} />
          <Route exact path={process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/mappingTableView'} component={TableViewMapping} />
          <Route exact path={process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/mappingGraphView'} component={MappingGraphBetweenOntologies} />
          <Route exact path={process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/generatemapping'} component={GenerateMapping} />
          <Route exact path={process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/mappingList/:id'} component={SearchMappingsPage} />

          <Route exact path={process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/semanticdiff'} component={HistoryManagement} />
          <Route exact path={process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/semanticdiff/table'} component={DiffTable} />
          <Route exact path={process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/semanticdiff/add'} component={AddRobotDiff} />
          <Route exact path={process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/semanticdiff/criteria'} component={Criteria} />
          <Route exact path={process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/semanticdiff/criteria/:id'} component={Criteria} />
          <Route exact path={process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/semanticdiff/timeline/:id'} component={TimelineOntology} />

          <Route exact path={process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/ondet'} component={ContoManagement} />
          <Route exact path={process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/ondet/add'} component={AddConto} />
          <Route exact path={process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/ondet/table'} component={DiffTable} />
          <Route exact path={process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/ondet/timeline'} component={ChangesTimeline} />
          <Route exact path={process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/webprotege'} component={WebProtege} />
          <Route exact path={process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/release'} component={ReleaseInfo} />
          <Route exact path={process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/funding'} component={FundingInfo} />
          <Route exact path={process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/annotation'} component={NerForm} />
          <Route exact path={process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/semanticSearch'} component={ModelSelector} />
          <Route exact path={process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/howtouse'} component={HowToUse} />
          <Route exact path={process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/pidChecker'} component={PIDChecker} />
        </SandboxLayout>
      </Route>
    </Switch>
  );
};

export default AppRouter;
